import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { SelectComponent, ButtonComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';
import Image from 'next/image';
import { useMeasure } from 'react-use';
import { useQuery } from 'react-query';
import PlacesAutocompleteComponent from '../Molecules/PlacesAutocompleteComponent';

// Utils
import strings from '../../constants/Strings';

// Constants
import colors from '../../constants/Colors';

// Images
import logo from '../../public/Images/logo.webp';
import searchIcon from '../../public/icons/search.svg';
import NoSSRComponent from '../Atoms/NoSSRComponent';
import SearchEngineService from '../../services/api/SearchEngineService';
import useIsMobile from '../../hooks/useIsMobile';

const SearchEngine = ({
  theme,
  withLogo,
  withLabel,
  color,
  buttonTheme,
  inputTheme,
  withSearchIcon,
  initValues,
  handleSubmit,
  isLoading,
  setCurrentValuesNames,
}) => {
  const [need, setNeed] = useState(null);
  const [needs, setNeeds] = useState(null);
  const [equipment, setEquipment] = useState(null);
  const [equipments, setEquipments] = useState(null);
  const [zipcode, setZipcode] = useState('');

  const [locationInputRef, { width: locationInputWidth }] = useMeasure();
  const [isMobile] = useIsMobile();

  // Queries
  const getPrestationTypes = useQuery('getPrestationTypes', () => SearchEngineService.getPrestationTypes());
  const getEquipmentTypes = useQuery('getEquipmentTypes', () => SearchEngineService.getEquipmentTypes());

  useEffect(() => {
    if (getPrestationTypes.isSuccess && getPrestationTypes.data) {
      const formattedTypes = getPrestationTypes.data?.data?.prestationTypes?.map(
        ({ id, code }) => ({ label: code, value: id }),
      );
      setNeeds(formattedTypes.concat([{ label: strings.payOnline, value: 0 }]));
    }
  }, [getPrestationTypes.isSuccess, getPrestationTypes.data]);

  useEffect(() => {
    if (getEquipmentTypes.isSuccess && getEquipmentTypes.data) {
      const formattedTypes = getEquipmentTypes.data?.data?.equipmentTypes?.map(
        ({ id, name }) => ({ label: name, value: id }),
      );
      setEquipments(formattedTypes);
    }
  }, [getEquipmentTypes.isSuccess, getEquipmentTypes.data]);

  useEffect(() => {
    if (initValues?.need && needs) {
      setNeed(needs.find(({ value }) => value.toString() === initValues.need));
    }
    if (initValues?.equipment && equipments && !equipment) {
      setEquipment(equipments.find(({ value }) => value.toString() === initValues.equipment));
    }
  }, [initValues, needs, equipments]);

  useEffect(() => {
    if (need?.value === 0) {
      setEquipment(null);
    }
  }, [need]);

  const sendDatalayer = () => {
    if (!window) {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'research',
      category: need?.label,
      type: equipment?.label,
      city: zipcode,
    });
  };

  // Methods
  const onSubmit = () => {
    if (need?.value !== 0 && (!zipcode || !need || !equipment)) {
      return false;
    }
    if (need?.value === 0 && !zipcode) {
      return false;
    }
    sendDatalayer();
    setCurrentValuesNames({
      need: need?.label,
      equipment: equipment?.label,
    });
    return handleSubmit({ need: need?.value, equipment: equipment?.value || undefined, zipcode });
  };

  const renderHeader = () => {
    if (!withLogo) {
      return null;
    }
    if (theme === 'light') {
      return (
        <>
          <Image alt="logo" src={logo} width={125} height={125} />
          <h5 className="pl-5 bold search-engine-items-width medium-weight m-0 flex-fill text-align-right">
            {strings.findPro}
          </h5>
        </>
      );
    }

    return <h2 className="medium-weight" style={{ color }}>{strings.findPro}</h2>;
  };

  return (
    <div className={
      `d-flex search-engine-container ${theme === 'full' ? 'ph-6 f-column'
        : 'f-row align-center full-width justify-between'}`
    }
    >
      {renderHeader()}
      <div className="d-flex align-end f-wrap">
        <div className={`
          ${theme === 'full' ? 'm-2' : 'mv-3 ml-3'}
          ${isMobile ? 'full-width' : 'search-engine-items-width'}
        `}
        >
          <NoSSRComponent>
            <SelectComponent
              data={needs}
              isSearchable={false}
              name="needs-select"
              value={need}
              setValue={setNeed}
              placeholder={strings.myNeed}
              isClearable={false}
              label={theme === 'full' || withLabel ? strings.iNeed : ''}
              theme={theme === 'full' ? inputTheme : 'dark'}
            />
          </NoSSRComponent>
        </div>
        <div className={`
        ${theme === 'full' ? 'm-2' : 'm-3'} 
        ${isMobile ? 'full-width' : 'search-engine-items-width'}
        ${need?.value === 0 ? 'opacity-05' : ''}
        `}
        >
          <NoSSRComponent>
            <SelectComponent
              data={equipments}
              isSearchable={false}
              name="equipments-select"
              value={equipment}
              setValue={setEquipment}
              placeholder={strings.equipmentType}
              isClearable={false}
              label={theme === 'full' || withLabel ? strings.equipmentType : ''}
              theme={theme === 'full' ? inputTheme : 'dark'}
              isDisabled={need?.value === 0}
            />
          </NoSSRComponent>
        </div>
        <div
          ref={locationInputRef}
          className={`${theme === 'full' ? 'm-2' : 'm-3'} ${isMobile ? 'full-width' : 'search-engine-items-width'}`}
        >
          <PlacesAutocompleteComponent
            setZipcode={setZipcode}
            theme={theme === 'full' ? inputTheme : 'dark'}
            label={theme === 'full' || withLabel ? strings.iLive : ''}
            initValue={initValues?.zipcode}
            onKeyDown={(e) => (e.key === 'Enter' ? onSubmit() : null)}
          />
        </div>
        <div
          className={`${theme === 'full' ? 'm-2' : 'm-3'} 
          ${isMobile ? 'full-width mt-4' : 'search-engine-items-width'}`}
        >
          <ButtonComponent onClick={onSubmit} theme={theme === 'full' ? buttonTheme : 'primary'}>
            <div
              className="d-flex justify-center align-center"
              style={{ width: `calc(${locationInputWidth}px - 32px)`, height: 'calc(58px - 19.2px)' }}
            >
              {isLoading
                ? <LoaderComponent size={30} borderWidth={3} color={colors.white} />
                : (
                  <>
                    <span className="uppercase mh-3">{strings.search}</span>
                    { theme === 'full' && !withSearchIcon
                      ? null
                      : (
                        <div>
                          <Image alt="search" src={searchIcon} width={20} height={20} />
                        </div>
                      )}
                  </>
                )}
            </div>
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

SearchEngine.propTypes = {
  theme: PropTypes.oneOf(['full', 'light']),
  withLogo: PropTypes.bool,
  withLabel: PropTypes.bool,
  withSearchIcon: PropTypes.bool,
  buttonTheme: PropTypes.string,
  inputTheme: PropTypes.string,
  color: PropTypes.string,
  initValues: PropTypes.shape({
    need: PropTypes.string,
    equipment: PropTypes.string,
    zipcode: PropTypes.string,
  }),
  handleSubmit: PropTypes.func.isRequired,
  setCurrentValuesNames: PropTypes.func,
  isLoading: PropTypes.bool,
};

SearchEngine.defaultProps = {
  theme: 'full',
  withLogo: true,
  withLabel: false,
  buttonTheme: 'black',
  inputTheme: 'light',
  color: colors.white,
  withSearchIcon: false,
  initValues: null,
  isLoading: false,
  setCurrentValuesNames: () => {},
};

export default SearchEngine;
