import client from './client';
import rpcClient from './rpcClient';

const getCitiesAndZipcodes = (prefix) => client.get('/postal-codes', {
  params: {
    prefix,
  },
});

const getPrestationTypes = () => client.get('/prestation-types');
const getEquipmentTypes = () => client.get('/equipment-types');

const getAgencies = ({ prestation, equipment, zipcode }) => rpcClient.get('/all-agencies-by-intervention-criteria', {
  params: {
    prestationTypeId: prestation === 0 ? undefined : prestation,
    equipmentTypeId: equipment,
    postalCode: zipcode,
  },
});

export default {
  getCitiesAndZipcodes,
  getPrestationTypes,
  getEquipmentTypes,
  getAgencies,
};
