import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { useQuery } from 'react-query';
import Link from 'next/link';

// Components
import Image from 'next/image';

// Images
import locationIcon from '../../public/icons/localisation.svg';
import NoSSRComponent from '../Atoms/NoSSRComponent';

// Utils
import strings from '../../constants/Strings';
import ZipcodeService from '../../services/api/SearchEngineService';
import useIsMobile from '../../hooks/useIsMobile';

const PlacesAutocompleteComponent = ({
  theme, setZipcode, label, initValue, onKeyDown,
}) => {
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);
  const [search, setSearch] = useState('');

  const [isMobile] = useIsMobile();

  const getCities = useQuery(['getCities', search], () => {
    if (search.length > 1) {
      return ZipcodeService.getCitiesAndZipcodes(search.replace(/\D/g, ''));
    }
    return false;
  });

  const formatPlacesValues = (postalCodes) => postalCodes?.reduce(
    (acc, { code, cities: allCities }) => [...acc, ...allCities.map(
      ({ name }) => ({ label: `${code} ${name}`, value: code }),
    )],
    [],
  );

  useEffect(() => {
    if (getCities.isSuccess && getCities.data) {
      setCities(formatPlacesValues(getCities?.data?.data?.postalCodes));
    }
  }, [getCities.isSuccess, getCities.data]);

  useEffect(() => {
    if (initValue) {
      setSearch(initValue);
      setCity({ label: initValue, value: initValue });
      setZipcode(initValue);
    }
  }, [initValue]);

  const DropdownIndicator = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <Image alt="location" src={locationIcon} width={20} />
    </components.DropdownIndicator>
  );

  const customStyle = {
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: () => ({
      color: '#4e5b74',
      margin: '5px 10px',
      cursor: 'pointer',
    }),
    control: () => ({
      backgroundColor: theme === 'dark' ? '#F4F5F9' : '#FFF',
      borderColor: theme === 'dark' ? 'rgba(31,35,44,0.12)' : '#F4F5F9',
      alignItems: 'center',
      borderRadius: '5px',
      borderStyle: 'solid',
      borderWidth: '1px',
      cursor: 'pointer',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: '11px 0',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#1f232c',
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: 500,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: 500,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#d01408' : '#1f232c',
      fontFamily: "'Work Sans', sans-serif",
    }),
  };

  const customTheme = (selectTheme) => ({
    ...selectTheme,
    colors: {
      ...selectTheme.colors,
      primary: '#f4f5f9',
      primary75: '#d01408',
      primary50: '#d01408',
      primary25: '#f4f5f9',
    },
  });

  return (
    <NoSSRComponent>
      <div>
        {label ? (
          <span
            className={`${
              theme === 'dark' ? 'grey-400-text' : 'white-06-text'
            } medium-text ml-2 relative`}
          >
            {label}
            <span
              className={`${
                theme === 'dark' ? 'grey-400-text grey-400-border' : 'white-06-border white-06-text'
              } medium-text ml-2 tooltip border-solid border-thin radius-5 ph-1 pointer`}
            >
              ?
              <span
                className="tooltiptext"
                style={isMobile ? { left: -20 } : {}}
              >
                <p className="very-small-text form-input-width">
                  <span className="mr-1 ws-pre-wrap">{strings.rgpd.placeAutocomplete}</span>
                  <Link href="/politique-de-confidentialite">
                    <a
                      className="underline"
                    >
                      <span>{strings.rgpd.privacyPolicy}</span>
                    </a>
                  </Link>
                </p>
              </span>
            </span>
          </span>
        ) : null}
        <Select
          components={{ DropdownIndicator }}
          styles={customStyle}
          isLoading={getCities.isLoading}
          isClearable
          isSearchable
          name="cities-select"
          value={city}
          onChange={(newValue) => {
            setCity(newValue);
            setZipcode(newValue?.value);
          }}
          options={cities}
          placeholder={strings.zipcode}
          onInputChange={setSearch}
          theme={customTheme}
          type="number"
          onKeyDown={onKeyDown}
          noOptionsMessage={() => strings.noResult}
        />
      </div>
    </NoSSRComponent>
  );
};

PlacesAutocompleteComponent.propTypes = {
  setZipcode: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  label: PropTypes.string,
  initValue: PropTypes.string,
  onKeyDown: PropTypes.func,
};

PlacesAutocompleteComponent.defaultProps = {
  theme: 'light',
  label: '',
  initValue: null,
  onKeyDown: null,
};

export default PlacesAutocompleteComponent;
